import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  RentDepositInfo,
  RentDepositModel,
  RentDepositPaymentOption,
  RuvApplianceStatus
} from '@ui/shared/models';
import {
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@ui/legacy-lib';

@Component({
  selector: 'app-rent-deposit-status-modal',
  templateUrl: './rent-deposit-status-modal.component.html',
  styleUrls: ['./rent-deposit-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    SvgIconComponent,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ],
  standalone: true
})
export class RentDepositStatusModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public rentDepositInfo: RentDepositInfo;
  public rentDepositModel: RentDepositModel;

  public get inRevision() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
        RuvApplianceStatus.UNDER_REVISION ||
      this.rentDepositInfo?.ruvData?.applianceStatus ===
        RuvApplianceStatus.TECHNICAL_PROBLEM
    );
  }

  public get isApproved() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.APPROVED
    );
  }

  public get isDeclined() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.DECLINED
    );
  }

  public get failed() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.REQUEST_FAILED
    );
  }

  public get isYearly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.YEARLY
    );
  }

  public get isMonthly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.MONTHLY
    );
  }

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
